import { Component, OnInit, ɵConsole } from '@angular/core';
import { Auth, Hub } from 'aws-amplify';
import { Router } from '@angular/router';
import { AmplifyService } from 'aws-amplify-angular';
// import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private amplifyService: AmplifyService
    // private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    Auth.federatedSignIn({customProvider: 'innovation-hub'});
  }


}
