import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import awsconfig from './aws-exports';
import Amplify, { Auth, Storage } from 'aws-amplify';

API.configure(awsconfig);
PubSub.configure(awsconfig);
Amplify.configure(awsconfig);
const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
  },
});

export function SetS3Config(level) {
Storage.configure({
  level : level,
  bucket: 'markitconnect-dev-storage', // REQUIRED -  Amazon S3 bucket
  region: 'eu-west-1',
  aws_cognito_identity_pool_id: 'eu-west-1:5dcabb3c-b7a2-4419-813c-2359b91d219d',
}
);
}
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
