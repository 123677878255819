/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import { Observable } from "zen-observable-ts";

export type CreateHTCampaignInput = {
  id?: string | null;
  title?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  imageURL?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  status?: CampaignStatus | null;
  theme?: string | null;
  department?: string | null;
  tags?: Array<string | null> | null;
  distributionList?: Array<string | null> | null;
  campaignType?: CampaignType | null;
  userId?: string | null;
  createdBy?: string | null;
  adminUser?: Array<string | null> | null;
  currentStep?: string | null;
  questionnaire?: QuestionnaireInput | null;
  supportingDocuement?: string | null;
};

export enum CampaignStatus {
  DRAFT = "DRAFT",
  INPROGRESS = "INPROGRESS",
  CLOSED = "CLOSED"
}

export enum CampaignType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
}

export type QuestionnaireInput = {
  id: string;
  questions?: Array<QuestionInput | null> | null;
  campaignId?: string | null;
  stepId?: string | null;
  ideaId?: string | null;
};

export type QuestionInput = {
  id: string;
  templateOptions?: FormlyTemplateOptionsInput | null;
  key: string;
  name?: string | null;
  type?: InputType | null;
  optionsTypes?: Array<string | null> | null;
  validation?: ValidationInput | null;
  template?: string | null;
  wrappers?: Array<string | null> | null;
  hide?: boolean | null;
  className?: string | null;
  fieldGroupClassName?: string | null;
  fieldGroup?: Array<QuestionInput | null> | null;
  focus?: boolean | null;
  defaultValue?: string | null;
  campaignQuestionId?: string | null;
};

export type FormlyTemplateOptionsInput = {
  type?: string | null;
  label?: string | null;
  placeholder?: string | null;
  disabled?: boolean | null;
  options?: Array<ComboOptionInput | null> | null;
  cols?: number | null;
  description?: string | null;
  hidden?: boolean | null;
  max?: number | null;
  min?: number | null;
  minLength?: number | null;
  pattern?: string | null;
  maxLength?: number | null;
  required?: boolean | null;
  tabindex?: number | null;
  readonly?: boolean | null;
  multiple?: boolean | null;
  selectAllOption?: string | null;
  rows?: number | null;
};

export type ComboOptionInput = {
  label?: string | null;
  value?: string | null;
};

export enum InputType {
  select = "select",
  file = "file",
  input = "input",
  textarea = "textarea",
  checkbox = "checkbox",
  radio = "radio"
}

export type ValidationInput = {
  id?: string | null;
  messages?: ValidationMassageInput | null;
};

export type ValidationMassageInput = {
  pattern?: string | null;
};

export type ModelHTCampaignConditionInput = {
  title?: ModelStringInput | null;
  shortDescription?: ModelStringInput | null;
  longDescription?: ModelStringInput | null;
  imageURL?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  status?: ModelCampaignStatusInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  distributionList?: ModelStringInput | null;
  campaignType?: ModelCampaignTypeInput | null;
  userId?: ModelIDInput | null;
  createdBy?: ModelStringInput | null;
  adminUser?: ModelStringInput | null;
  currentStep?: ModelStringInput | null;
  supportingDocuement?: ModelStringInput | null;
  and?: Array<ModelHTCampaignConditionInput | null> | null;
  or?: Array<ModelHTCampaignConditionInput | null> | null;
  not?: ModelHTCampaignConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelCampaignStatusInput = {
  eq?: CampaignStatus | null;
  ne?: CampaignStatus | null;
};

export type ModelCampaignTypeInput = {
  eq?: CampaignType | null;
  ne?: CampaignType | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateHTCampaignInput = {
  id: string;
  title?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  imageURL?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  status?: CampaignStatus | null;
  theme?: string | null;
  department?: string | null;
  tags?: Array<string | null> | null;
  distributionList?: Array<string | null> | null;
  campaignType?: CampaignType | null;
  userId?: string | null;
  createdBy?: string | null;
  adminUser?: Array<string | null> | null;
  currentStep?: string | null;
  questionnaire?: QuestionnaireInput | null;
  supportingDocuement?: string | null;
};

export type DeleteHTCampaignInput = {
  id?: string | null;
};

export type CreateWorkflowStepInput = {
  id?: string | null;
  sequence?: number | null;
  stepName?: string | null;
  role?: Role | null;
  campaignId?: string | null;
  campaignName?: string | null;
  tags?: Array<string | null> | null;
  theme?: string | null;
  department?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  questionnaire?: QuestionnaireInput | null;
  visibility?: string | null;
  autoApproval?: boolean | null;
};

export enum Role {
  ADMIN = "ADMIN",
  CONTRIBUTOR = "CONTRIBUTOR",
  COMMON = "COMMON"
}

export type ModelWorkflowStepConditionInput = {
  sequence?: ModelIntInput | null;
  stepName?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  campaignId?: ModelIDInput | null;
  campaignName?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  visibility?: ModelStringInput | null;
  autoApproval?: ModelBooleanInput | null;
  and?: Array<ModelWorkflowStepConditionInput | null> | null;
  or?: Array<ModelWorkflowStepConditionInput | null> | null;
  not?: ModelWorkflowStepConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelRoleInput = {
  eq?: Role | null;
  ne?: Role | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateWorkflowStepInput = {
  id: string;
  sequence?: number | null;
  stepName?: string | null;
  role?: Role | null;
  campaignId?: string | null;
  campaignName?: string | null;
  tags?: Array<string | null> | null;
  theme?: string | null;
  department?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  questionnaire?: QuestionnaireInput | null;
  visibility?: string | null;
  autoApproval?: boolean | null;
};

export type DeleteWorkflowStepInput = {
  id?: string | null;
};

export type CreateUserInput = {
  udType?: string | null;
  id?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  userType?: UserType | null;
  required?: boolean | null;
  emailId?: string | null;
  favIdea?: Array<string | null> | null;
  score?: number | null;
};

export enum UserType {
  ADMIN = "ADMIN",
  COMMON = "COMMON"
}

export type ModelUserConditionInput = {
  udType?: ModelStringInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  required?: ModelBooleanInput | null;
  emailId?: ModelStringInput | null;
  favIdea?: ModelIDInput | null;
  score?: ModelIntInput | null;
  and?: Array<ModelUserConditionInput | null> | null;
  or?: Array<ModelUserConditionInput | null> | null;
  not?: ModelUserConditionInput | null;
};

export type ModelUserTypeInput = {
  eq?: UserType | null;
  ne?: UserType | null;
};

export type UpdateUserInput = {
  udType?: string | null;
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  userType?: UserType | null;
  required?: boolean | null;
  emailId?: string | null;
  favIdea?: Array<string | null> | null;
  score?: number | null;
};

export type DeleteUserInput = {
  id?: string | null;
};

export type CreateHTIdeaInput = {
  id?: string | null;
  title?: string | null;
  campaignId: string;
  shortDescription?: string | null;
  longDescription?: string | null;
  like?: Array<string | null> | null;
  comments?: Array<CommentInput | null> | null;
  tags?: Array<string | null> | null;
  contributor?: Array<string | null> | null;
  author?: string | null;
  postedDate?: string | null;
  ideaStatus?: IdeaStatus | null;
  lastUpdatedBy?: string | null;
  ragStatus?: RagStatus | null;
  questionnaire?: QuestionnaireInput | null;
  modelValue?: Array<AnswerInput | null> | null;
  theme?: string | null;
  department?: string | null;
  fav?: Array<string | null> | null;
  campaignName?: string | null;
  visibility?: string | null;
  promotionImageURL?: string | null;
  supportingDocName?: string | null;
  likesCount?: number | null;
  commentCount?: number | null;
  favCount?: number | null;
  type?: string | null;
  ideaTheme?: string | null;
};

export type CommentInput = {
  commnetText?: string | null;
  emailId?: string | null;
};

export enum IdeaStatus {
  SUBMISSION = "SUBMISSION",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED"
}

export enum RagStatus {
  RED = "RED",
  AMBER = "AMBER",
  GREEN = "GREEN"
}

export type AnswerInput = {
  key?: string | null;
  value?: string | null;
  multiValue?: Array<string | null> | null;
};

export type ModelHTIdeaConditionInput = {
  title?: ModelStringInput | null;
  campaignId?: ModelIDInput | null;
  shortDescription?: ModelStringInput | null;
  longDescription?: ModelStringInput | null;
  like?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  contributor?: ModelStringInput | null;
  author?: ModelStringInput | null;
  postedDate?: ModelStringInput | null;
  ideaStatus?: ModelIdeaStatusInput | null;
  lastUpdatedBy?: ModelStringInput | null;
  ragStatus?: ModelRagStatusInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  fav?: ModelStringInput | null;
  campaignName?: ModelStringInput | null;
  visibility?: ModelStringInput | null;
  promotionImageURL?: ModelStringInput | null;
  supportingDocName?: ModelStringInput | null;
  likesCount?: ModelIntInput | null;
  commentCount?: ModelIntInput | null;
  favCount?: ModelIntInput | null;
  type?: ModelStringInput | null;
  ideaTheme?: ModelStringInput | null;
  and?: Array<ModelHTIdeaConditionInput | null> | null;
  or?: Array<ModelHTIdeaConditionInput | null> | null;
  not?: ModelHTIdeaConditionInput | null;
};

export type ModelIdeaStatusInput = {
  eq?: IdeaStatus | null;
  ne?: IdeaStatus | null;
};

export type ModelRagStatusInput = {
  eq?: RagStatus | null;
  ne?: RagStatus | null;
};

export type UpdateHTIdeaInput = {
  id: string;
  title?: string | null;
  campaignId?: string | null;
  shortDescription?: string | null;
  longDescription?: string | null;
  like?: Array<string | null> | null;
  comments?: Array<CommentInput | null> | null;
  tags?: Array<string | null> | null;
  contributor?: Array<string | null> | null;
  author?: string | null;
  postedDate?: string | null;
  ideaStatus?: IdeaStatus | null;
  lastUpdatedBy?: string | null;
  ragStatus?: RagStatus | null;
  questionnaire?: QuestionnaireInput | null;
  modelValue?: Array<AnswerInput | null> | null;
  theme?: string | null;
  department?: string | null;
  fav?: Array<string | null> | null;
  campaignName?: string | null;
  visibility?: string | null;
  promotionImageURL?: string | null;
  supportingDocName?: string | null;
  likesCount?: number | null;
  commentCount?: number | null;
  favCount?: number | null;
  type?: string | null;
  ideaTheme?: string | null;
};

export type DeleteHTIdeaInput = {
  id?: string | null;
};

export type CreateTrafficLogInput = {
  id?: string | null;
  userEmail: string;
  action: string;
  date?: string | null;
  context?: string | null;
};

export type ModelTrafficLogConditionInput = {
  userEmail?: ModelStringInput | null;
  action?: ModelStringInput | null;
  date?: ModelStringInput | null;
  context?: ModelStringInput | null;
  and?: Array<ModelTrafficLogConditionInput | null> | null;
  or?: Array<ModelTrafficLogConditionInput | null> | null;
  not?: ModelTrafficLogConditionInput | null;
};

export type UpdateTrafficLogInput = {
  id: string;
  userEmail?: string | null;
  action?: string | null;
  date?: string | null;
  context?: string | null;
};

export type DeleteTrafficLogInput = {
  id?: string | null;
};

export type ModelHTCampaignFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  shortDescription?: ModelStringInput | null;
  longDescription?: ModelStringInput | null;
  imageURL?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  status?: ModelCampaignStatusInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  distributionList?: ModelStringInput | null;
  campaignType?: ModelCampaignTypeInput | null;
  userId?: ModelIDInput | null;
  createdBy?: ModelStringInput | null;
  adminUser?: ModelStringInput | null;
  currentStep?: ModelStringInput | null;
  supportingDocuement?: ModelStringInput | null;
  and?: Array<ModelHTCampaignFilterInput | null> | null;
  or?: Array<ModelHTCampaignFilterInput | null> | null;
  not?: ModelHTCampaignFilterInput | null;
};

export type ModelWorkflowStepFilterInput = {
  id?: ModelIDInput | null;
  sequence?: ModelIntInput | null;
  stepName?: ModelStringInput | null;
  role?: ModelRoleInput | null;
  campaignId?: ModelIDInput | null;
  campaignName?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  startDate?: ModelStringInput | null;
  endDate?: ModelStringInput | null;
  visibility?: ModelStringInput | null;
  autoApproval?: ModelBooleanInput | null;
  and?: Array<ModelWorkflowStepFilterInput | null> | null;
  or?: Array<ModelWorkflowStepFilterInput | null> | null;
  not?: ModelWorkflowStepFilterInput | null;
};

export type ModelUserFilterInput = {
  udType?: ModelStringInput | null;
  id?: ModelIDInput | null;
  firstName?: ModelStringInput | null;
  lastName?: ModelStringInput | null;
  userType?: ModelUserTypeInput | null;
  required?: ModelBooleanInput | null;
  emailId?: ModelStringInput | null;
  favIdea?: ModelIDInput | null;
  score?: ModelIntInput | null;
  and?: Array<ModelUserFilterInput | null> | null;
  or?: Array<ModelUserFilterInput | null> | null;
  not?: ModelUserFilterInput | null;
};

export type ModelHTIdeaFilterInput = {
  id?: ModelIDInput | null;
  title?: ModelStringInput | null;
  campaignId?: ModelIDInput | null;
  shortDescription?: ModelStringInput | null;
  longDescription?: ModelStringInput | null;
  like?: ModelStringInput | null;
  tags?: ModelStringInput | null;
  contributor?: ModelStringInput | null;
  author?: ModelStringInput | null;
  postedDate?: ModelStringInput | null;
  ideaStatus?: ModelIdeaStatusInput | null;
  lastUpdatedBy?: ModelStringInput | null;
  ragStatus?: ModelRagStatusInput | null;
  theme?: ModelStringInput | null;
  department?: ModelStringInput | null;
  fav?: ModelStringInput | null;
  campaignName?: ModelStringInput | null;
  visibility?: ModelStringInput | null;
  promotionImageURL?: ModelStringInput | null;
  supportingDocName?: ModelStringInput | null;
  likesCount?: ModelIntInput | null;
  commentCount?: ModelIntInput | null;
  favCount?: ModelIntInput | null;
  type?: ModelStringInput | null;
  ideaTheme?: ModelStringInput | null;
  and?: Array<ModelHTIdeaFilterInput | null> | null;
  or?: Array<ModelHTIdeaFilterInput | null> | null;
  not?: ModelHTIdeaFilterInput | null;
};

export type ModelTrafficLogFilterInput = {
  id?: ModelIDInput | null;
  userEmail?: ModelStringInput | null;
  action?: ModelStringInput | null;
  date?: ModelStringInput | null;
  context?: ModelStringInput | null;
  and?: Array<ModelTrafficLogFilterInput | null> | null;
  or?: Array<ModelTrafficLogFilterInput | null> | null;
  not?: ModelTrafficLogFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelIntKeyConditionInput = {
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type CreateHtCampaignMutation = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateHtCampaignMutation = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteHtCampaignMutation = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateWorkflowStepMutation = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateWorkflowStepMutation = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteWorkflowStepMutation = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUserMutation = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateUserMutation = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteUserMutation = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateHtIdeaMutation = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateHtIdeaMutation = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteHtIdeaMutation = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateTrafficLogMutation = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTrafficLogMutation = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTrafficLogMutation = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetHtCampaignQuery = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListHtCampaignsQuery = {
  __typename: "ModelHTCampaignConnection";
  items: Array<{
    __typename: "HTCampaign";
    id: string;
    title: string | null;
    shortDescription: string | null;
    longDescription: string | null;
    imageURL: string | null;
    startDate: string | null;
    endDate: string | null;
    status: CampaignStatus | null;
    theme: string | null;
    department: string | null;
    tags: Array<string | null> | null;
    distributionList: Array<string | null> | null;
    campaignType: CampaignType | null;
    userId: string | null;
    createdBy: string | null;
    adminUser: Array<string | null> | null;
    currentStep: string | null;
    supportingDocuement: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetWorkflowStepQuery = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type ListWorkflowStepsQuery = {
  __typename: "ModelWorkflowStepConnection";
  items: Array<{
    __typename: "WorkflowStep";
    id: string;
    sequence: number | null;
    stepName: string | null;
    role: Role | null;
    campaignId: string | null;
    campaignName: string | null;
    tags: Array<string | null> | null;
    theme: string | null;
    department: string | null;
    startDate: string | null;
    endDate: string | null;
    visibility: string | null;
    autoApproval: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListUsersQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    udType: string | null;
    id: string;
    firstName: string | null;
    lastName: string | null;
    userType: UserType | null;
    required: boolean | null;
    emailId: string | null;
    favIdea: Array<string | null> | null;
    score: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetHtIdeaQuery = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListHtIdeasQuery = {
  __typename: "ModelHTIdeaConnection";
  items: Array<{
    __typename: "HTIdea";
    id: string;
    title: string | null;
    campaignId: string;
    shortDescription: string | null;
    longDescription: string | null;
    like: Array<string | null> | null;
    tags: Array<string | null> | null;
    contributor: Array<string | null> | null;
    author: string | null;
    postedDate: string | null;
    ideaStatus: IdeaStatus | null;
    lastUpdatedBy: string | null;
    ragStatus: RagStatus | null;
    theme: string | null;
    department: string | null;
    fav: Array<string | null> | null;
    campaignName: string | null;
    visibility: string | null;
    promotionImageURL: string | null;
    supportingDocName: string | null;
    likesCount: number | null;
    commentCount: number | null;
    favCount: number | null;
    type: string | null;
    ideaTheme: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetTrafficLogQuery = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListTrafficLogsQuery = {
  __typename: "ModelTrafficLogConnection";
  items: Array<{
    __typename: "TrafficLog";
    id: string;
    userEmail: string;
    action: string;
    date: string | null;
    context: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type WorkstepByCampaignIdAndStepQuery = {
  __typename: "ModelWorkflowStepConnection";
  items: Array<{
    __typename: "WorkflowStep";
    id: string;
    sequence: number | null;
    stepName: string | null;
    role: Role | null;
    campaignId: string | null;
    campaignName: string | null;
    tags: Array<string | null> | null;
    theme: string | null;
    department: string | null;
    startDate: string | null;
    endDate: string | null;
    visibility: string | null;
    autoApproval: boolean | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type UserbyScoreQuery = {
  __typename: "ModelUserConnection";
  items: Array<{
    __typename: "User";
    udType: string | null;
    id: string;
    firstName: string | null;
    lastName: string | null;
    userType: UserType | null;
    required: boolean | null;
    emailId: string | null;
    favIdea: Array<string | null> | null;
    score: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type IdeaByThemeQuery = {
  __typename: "ModelHTIdeaConnection";
  items: Array<{
    __typename: "HTIdea";
    id: string;
    title: string | null;
    campaignId: string;
    shortDescription: string | null;
    longDescription: string | null;
    like: Array<string | null> | null;
    tags: Array<string | null> | null;
    contributor: Array<string | null> | null;
    author: string | null;
    postedDate: string | null;
    ideaStatus: IdeaStatus | null;
    lastUpdatedBy: string | null;
    ragStatus: RagStatus | null;
    theme: string | null;
    department: string | null;
    fav: Array<string | null> | null;
    campaignName: string | null;
    visibility: string | null;
    promotionImageURL: string | null;
    supportingDocName: string | null;
    likesCount: number | null;
    commentCount: number | null;
    favCount: number | null;
    type: string | null;
    ideaTheme: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type IdeaByLikesQuery = {
  __typename: "ModelHTIdeaConnection";
  items: Array<{
    __typename: "HTIdea";
    id: string;
    title: string | null;
    campaignId: string;
    shortDescription: string | null;
    longDescription: string | null;
    like: Array<string | null> | null;
    tags: Array<string | null> | null;
    contributor: Array<string | null> | null;
    author: string | null;
    postedDate: string | null;
    ideaStatus: IdeaStatus | null;
    lastUpdatedBy: string | null;
    ragStatus: RagStatus | null;
    theme: string | null;
    department: string | null;
    fav: Array<string | null> | null;
    campaignName: string | null;
    visibility: string | null;
    promotionImageURL: string | null;
    supportingDocName: string | null;
    likesCount: number | null;
    commentCount: number | null;
    favCount: number | null;
    type: string | null;
    ideaTheme: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type IdeaByDateQuery = {
  __typename: "ModelHTIdeaConnection";
  items: Array<{
    __typename: "HTIdea";
    id: string;
    title: string | null;
    campaignId: string;
    shortDescription: string | null;
    longDescription: string | null;
    like: Array<string | null> | null;
    tags: Array<string | null> | null;
    contributor: Array<string | null> | null;
    author: string | null;
    postedDate: string | null;
    ideaStatus: IdeaStatus | null;
    lastUpdatedBy: string | null;
    ragStatus: RagStatus | null;
    theme: string | null;
    department: string | null;
    fav: Array<string | null> | null;
    campaignName: string | null;
    visibility: string | null;
    promotionImageURL: string | null;
    supportingDocName: string | null;
    likesCount: number | null;
    commentCount: number | null;
    favCount: number | null;
    type: string | null;
    ideaTheme: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateHtCampaignSubscription = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateHtCampaignSubscription = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteHtCampaignSubscription = {
  __typename: "HTCampaign";
  id: string;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  imageURL: string | null;
  startDate: string | null;
  endDate: string | null;
  status: CampaignStatus | null;
  theme: string | null;
  department: string | null;
  tags: Array<string | null> | null;
  distributionList: Array<string | null> | null;
  campaignType: CampaignType | null;
  idea: {
    __typename: "ModelHTIdeaConnection";
    nextToken: string | null;
  } | null;
  userId: string | null;
  createdBy: string | null;
  adminUser: Array<string | null> | null;
  currentStep: string | null;
  steps: {
    __typename: "ModelWorkflowStepConnection";
    nextToken: string | null;
  } | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  supportingDocuement: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateWorkflowStepSubscription = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateWorkflowStepSubscription = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteWorkflowStepSubscription = {
  __typename: "WorkflowStep";
  id: string;
  sequence: number | null;
  stepName: string | null;
  role: Role | null;
  campaignId: string | null;
  campaignName: string | null;
  tags: Array<string | null> | null;
  theme: string | null;
  department: string | null;
  startDate: string | null;
  endDate: string | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  visibility: string | null;
  autoApproval: boolean | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUserSubscription = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateUserSubscription = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteUserSubscription = {
  __typename: "User";
  udType: string | null;
  id: string;
  firstName: string | null;
  lastName: string | null;
  userType: UserType | null;
  required: boolean | null;
  emailId: string | null;
  favIdea: Array<string | null> | null;
  score: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateHtIdeaSubscription = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateHtIdeaSubscription = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteHtIdeaSubscription = {
  __typename: "HTIdea";
  id: string;
  title: string | null;
  campaignId: string;
  shortDescription: string | null;
  longDescription: string | null;
  like: Array<string | null> | null;
  comments: Array<{
    __typename: "Comment";
    commnetText: string | null;
    emailId: string | null;
  } | null> | null;
  tags: Array<string | null> | null;
  contributor: Array<string | null> | null;
  author: string | null;
  postedDate: string | null;
  ideaStatus: IdeaStatus | null;
  lastUpdatedBy: string | null;
  ragStatus: RagStatus | null;
  questionnaire: {
    __typename: "Questionnaire";
    id: string;
    campaignId: string | null;
    stepId: string | null;
    ideaId: string | null;
  } | null;
  modelValue: Array<{
    __typename: "Answer";
    key: string | null;
    value: string | null;
    multiValue: Array<string | null> | null;
  } | null> | null;
  theme: string | null;
  department: string | null;
  fav: Array<string | null> | null;
  campaignName: string | null;
  visibility: string | null;
  promotionImageURL: string | null;
  supportingDocName: string | null;
  likesCount: number | null;
  commentCount: number | null;
  favCount: number | null;
  type: string | null;
  ideaTheme: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTrafficLogSubscription = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTrafficLogSubscription = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTrafficLogSubscription = {
  __typename: "TrafficLog";
  id: string;
  userEmail: string;
  action: string;
  date: string | null;
  context: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateHtCampaign(
    input: CreateHTCampaignInput,
    condition?: ModelHTCampaignConditionInput
  ): Promise<CreateHtCampaignMutation> {
    const statement = `mutation CreateHtCampaign($input: CreateHTCampaignInput!, $condition: ModelHTCampaignConditionInput) {
        createHTCampaign(input: $input, condition: $condition) {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateHtCampaignMutation>response.data.createHTCampaign;
  }
  async UpdateHtCampaign(
    input: UpdateHTCampaignInput,
    condition?: ModelHTCampaignConditionInput
  ): Promise<UpdateHtCampaignMutation> {
    const statement = `mutation UpdateHtCampaign($input: UpdateHTCampaignInput!, $condition: ModelHTCampaignConditionInput) {
        updateHTCampaign(input: $input, condition: $condition) {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateHtCampaignMutation>response.data.updateHTCampaign;
  }
  async DeleteHtCampaign(
    input: DeleteHTCampaignInput,
    condition?: ModelHTCampaignConditionInput
  ): Promise<DeleteHtCampaignMutation> {
    const statement = `mutation DeleteHtCampaign($input: DeleteHTCampaignInput!, $condition: ModelHTCampaignConditionInput) {
        deleteHTCampaign(input: $input, condition: $condition) {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteHtCampaignMutation>response.data.deleteHTCampaign;
  }
  async CreateWorkflowStep(
    input: CreateWorkflowStepInput,
    condition?: ModelWorkflowStepConditionInput
  ): Promise<CreateWorkflowStepMutation> {
    const statement = `mutation CreateWorkflowStep($input: CreateWorkflowStepInput!, $condition: ModelWorkflowStepConditionInput) {
        createWorkflowStep(input: $input, condition: $condition) {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWorkflowStepMutation>response.data.createWorkflowStep;
  }
  async UpdateWorkflowStep(
    input: UpdateWorkflowStepInput,
    condition?: ModelWorkflowStepConditionInput
  ): Promise<UpdateWorkflowStepMutation> {
    const statement = `mutation UpdateWorkflowStep($input: UpdateWorkflowStepInput!, $condition: ModelWorkflowStepConditionInput) {
        updateWorkflowStep(input: $input, condition: $condition) {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWorkflowStepMutation>response.data.updateWorkflowStep;
  }
  async DeleteWorkflowStep(
    input: DeleteWorkflowStepInput,
    condition?: ModelWorkflowStepConditionInput
  ): Promise<DeleteWorkflowStepMutation> {
    const statement = `mutation DeleteWorkflowStep($input: DeleteWorkflowStepInput!, $condition: ModelWorkflowStepConditionInput) {
        deleteWorkflowStep(input: $input, condition: $condition) {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWorkflowStepMutation>response.data.deleteWorkflowStep;
  }
  async CreateUser(
    input: CreateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<CreateUserMutation> {
    const statement = `mutation CreateUser($input: CreateUserInput!, $condition: ModelUserConditionInput) {
        createUser(input: $input, condition: $condition) {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserMutation>response.data.createUser;
  }
  async UpdateUser(
    input: UpdateUserInput,
    condition?: ModelUserConditionInput
  ): Promise<UpdateUserMutation> {
    const statement = `mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
        updateUser(input: $input, condition: $condition) {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserMutation>response.data.updateUser;
  }
  async DeleteUser(
    input: DeleteUserInput,
    condition?: ModelUserConditionInput
  ): Promise<DeleteUserMutation> {
    const statement = `mutation DeleteUser($input: DeleteUserInput!, $condition: ModelUserConditionInput) {
        deleteUser(input: $input, condition: $condition) {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserMutation>response.data.deleteUser;
  }
  async CreateHtIdea(
    input: CreateHTIdeaInput,
    condition?: ModelHTIdeaConditionInput
  ): Promise<CreateHtIdeaMutation> {
    const statement = `mutation CreateHtIdea($input: CreateHTIdeaInput!, $condition: ModelHTIdeaConditionInput) {
        createHTIdea(input: $input, condition: $condition) {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateHtIdeaMutation>response.data.createHTIdea;
  }
  async UpdateHtIdea(
    input: UpdateHTIdeaInput,
    condition?: ModelHTIdeaConditionInput
  ): Promise<UpdateHtIdeaMutation> {
    const statement = `mutation UpdateHtIdea($input: UpdateHTIdeaInput!, $condition: ModelHTIdeaConditionInput) {
        updateHTIdea(input: $input, condition: $condition) {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateHtIdeaMutation>response.data.updateHTIdea;
  }
  async DeleteHtIdea(
    input: DeleteHTIdeaInput,
    condition?: ModelHTIdeaConditionInput
  ): Promise<DeleteHtIdeaMutation> {
    const statement = `mutation DeleteHtIdea($input: DeleteHTIdeaInput!, $condition: ModelHTIdeaConditionInput) {
        deleteHTIdea(input: $input, condition: $condition) {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteHtIdeaMutation>response.data.deleteHTIdea;
  }
  async CreateTrafficLog(
    input: CreateTrafficLogInput,
    condition?: ModelTrafficLogConditionInput
  ): Promise<CreateTrafficLogMutation> {
    const statement = `mutation CreateTrafficLog($input: CreateTrafficLogInput!, $condition: ModelTrafficLogConditionInput) {
        createTrafficLog(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTrafficLogMutation>response.data.createTrafficLog;
  }
  async UpdateTrafficLog(
    input: UpdateTrafficLogInput,
    condition?: ModelTrafficLogConditionInput
  ): Promise<UpdateTrafficLogMutation> {
    const statement = `mutation UpdateTrafficLog($input: UpdateTrafficLogInput!, $condition: ModelTrafficLogConditionInput) {
        updateTrafficLog(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTrafficLogMutation>response.data.updateTrafficLog;
  }
  async DeleteTrafficLog(
    input: DeleteTrafficLogInput,
    condition?: ModelTrafficLogConditionInput
  ): Promise<DeleteTrafficLogMutation> {
    const statement = `mutation DeleteTrafficLog($input: DeleteTrafficLogInput!, $condition: ModelTrafficLogConditionInput) {
        deleteTrafficLog(input: $input, condition: $condition) {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTrafficLogMutation>response.data.deleteTrafficLog;
  }
  async GetHtCampaign(id: string): Promise<GetHtCampaignQuery> {
    const statement = `query GetHtCampaign($id: ID!) {
        getHTCampaign(id: $id) {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetHtCampaignQuery>response.data.getHTCampaign;
  }
  async ListHtCampaigns(
    filter?: ModelHTCampaignFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListHtCampaignsQuery> {
    const statement = `query ListHtCampaigns($filter: ModelHTCampaignFilterInput, $limit: Int, $nextToken: String) {
        listHTCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            shortDescription
            longDescription
            imageURL
            startDate
            endDate
            status
            theme
            department
            tags
            distributionList
            campaignType
            userId
            createdBy
            adminUser
            currentStep
            supportingDocuement
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListHtCampaignsQuery>response.data.listHTCampaigns;
  }
  async GetWorkflowStep(id: string): Promise<GetWorkflowStepQuery> {
    const statement = `query GetWorkflowStep($id: ID!) {
        getWorkflowStep(id: $id) {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWorkflowStepQuery>response.data.getWorkflowStep;
  }
  async ListWorkflowSteps(
    filter?: ModelWorkflowStepFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWorkflowStepsQuery> {
    const statement = `query ListWorkflowSteps($filter: ModelWorkflowStepFilterInput, $limit: Int, $nextToken: String) {
        listWorkflowSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            sequence
            stepName
            role
            campaignId
            campaignName
            tags
            theme
            department
            startDate
            endDate
            visibility
            autoApproval
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWorkflowStepsQuery>response.data.listWorkflowSteps;
  }
  async GetUser(id: string): Promise<GetUserQuery> {
    const statement = `query GetUser($id: ID!) {
        getUser(id: $id) {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserQuery>response.data.getUser;
  }
  async ListUsers(
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsersQuery> {
    const statement = `query ListUsers($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            udType
            id
            firstName
            lastName
            userType
            required
            emailId
            favIdea
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsersQuery>response.data.listUsers;
  }
  async GetHtIdea(id: string): Promise<GetHtIdeaQuery> {
    const statement = `query GetHtIdea($id: ID!) {
        getHTIdea(id: $id) {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetHtIdeaQuery>response.data.getHTIdea;
  }
  async ListHtIdeas(
    filter?: ModelHTIdeaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListHtIdeasQuery> {
    const statement = `query ListHtIdeas($filter: ModelHTIdeaFilterInput, $limit: Int, $nextToken: String) {
        listHTIdeas(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            campaignId
            shortDescription
            longDescription
            like
            tags
            contributor
            author
            postedDate
            ideaStatus
            lastUpdatedBy
            ragStatus
            theme
            department
            fav
            campaignName
            visibility
            promotionImageURL
            supportingDocName
            likesCount
            commentCount
            favCount
            type
            ideaTheme
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListHtIdeasQuery>response.data.listHTIdeas;
  }
  async GetTrafficLog(id: string): Promise<GetTrafficLogQuery> {
    const statement = `query GetTrafficLog($id: ID!) {
        getTrafficLog(id: $id) {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTrafficLogQuery>response.data.getTrafficLog;
  }
  async ListTrafficLogs(
    filter?: ModelTrafficLogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTrafficLogsQuery> {
    const statement = `query ListTrafficLogs($filter: ModelTrafficLogFilterInput, $limit: Int, $nextToken: String) {
        listTrafficLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            userEmail
            action
            date
            context
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTrafficLogsQuery>response.data.listTrafficLogs;
  }
  async WorkstepByCampaignIdAndStep(
    campaignId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelWorkflowStepFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<WorkstepByCampaignIdAndStepQuery> {
    const statement = `query WorkstepByCampaignIdAndStep($campaignId: ID, $sortDirection: ModelSortDirection, $filter: ModelWorkflowStepFilterInput, $limit: Int, $nextToken: String) {
        workstepByCampaignIdAndStep(campaignId: $campaignId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            sequence
            stepName
            role
            campaignId
            campaignName
            tags
            theme
            department
            startDate
            endDate
            visibility
            autoApproval
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (campaignId) {
      gqlAPIServiceArguments.campaignId = campaignId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <WorkstepByCampaignIdAndStepQuery>(
      response.data.workstepByCampaignIdAndStep
    );
  }
  async UserbyScore(
    udType?: string,
    score?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelUserFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<UserbyScoreQuery> {
    const statement = `query UserbyScore($udType: String, $score: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
        userbyScore(udType: $udType, score: $score, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            udType
            id
            firstName
            lastName
            userType
            required
            emailId
            favIdea
            score
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (udType) {
      gqlAPIServiceArguments.udType = udType;
    }
    if (score) {
      gqlAPIServiceArguments.score = score;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserbyScoreQuery>response.data.userbyScore;
  }
  async IdeaByTheme(
    ideaTheme?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelHTIdeaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IdeaByThemeQuery> {
    const statement = `query IdeaByTheme($ideaTheme: String, $sortDirection: ModelSortDirection, $filter: ModelHTIdeaFilterInput, $limit: Int, $nextToken: String) {
        ideaByTheme(ideaTheme: $ideaTheme, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            campaignId
            shortDescription
            longDescription
            like
            tags
            contributor
            author
            postedDate
            ideaStatus
            lastUpdatedBy
            ragStatus
            theme
            department
            fav
            campaignName
            visibility
            promotionImageURL
            supportingDocName
            likesCount
            commentCount
            favCount
            type
            ideaTheme
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ideaTheme) {
      gqlAPIServiceArguments.ideaTheme = ideaTheme;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IdeaByThemeQuery>response.data.ideaByTheme;
  }
  async IdeaByLikes(
    type?: string,
    likesCount?: ModelIntKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelHTIdeaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IdeaByLikesQuery> {
    const statement = `query IdeaByLikes($type: String, $likesCount: ModelIntKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelHTIdeaFilterInput, $limit: Int, $nextToken: String) {
        ideaByLikes(type: $type, likesCount: $likesCount, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            campaignId
            shortDescription
            longDescription
            like
            tags
            contributor
            author
            postedDate
            ideaStatus
            lastUpdatedBy
            ragStatus
            theme
            department
            fav
            campaignName
            visibility
            promotionImageURL
            supportingDocName
            likesCount
            commentCount
            favCount
            type
            ideaTheme
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (likesCount) {
      gqlAPIServiceArguments.likesCount = likesCount;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IdeaByLikesQuery>response.data.ideaByLikes;
  }
  async IdeaByDate(
    type?: string,
    postedDate?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelHTIdeaFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<IdeaByDateQuery> {
    const statement = `query IdeaByDate($type: String, $postedDate: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelHTIdeaFilterInput, $limit: Int, $nextToken: String) {
        ideaByDate(type: $type, postedDate: $postedDate, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            campaignId
            shortDescription
            longDescription
            like
            tags
            contributor
            author
            postedDate
            ideaStatus
            lastUpdatedBy
            ragStatus
            theme
            department
            fav
            campaignName
            visibility
            promotionImageURL
            supportingDocName
            likesCount
            commentCount
            favCount
            type
            ideaTheme
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (postedDate) {
      gqlAPIServiceArguments.postedDate = postedDate;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <IdeaByDateQuery>response.data.ideaByDate;
  }
  OnCreateHtCampaignListener: Observable<
    OnCreateHtCampaignSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateHtCampaign {
        onCreateHTCampaign {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateHtCampaignSubscription>;

  OnUpdateHtCampaignListener: Observable<
    OnUpdateHtCampaignSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateHtCampaign {
        onUpdateHTCampaign {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateHtCampaignSubscription>;

  OnDeleteHtCampaignListener: Observable<
    OnDeleteHtCampaignSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteHtCampaign {
        onDeleteHTCampaign {
          __typename
          id
          title
          shortDescription
          longDescription
          imageURL
          startDate
          endDate
          status
          theme
          department
          tags
          distributionList
          campaignType
          idea {
            __typename
            nextToken
          }
          userId
          createdBy
          adminUser
          currentStep
          steps {
            __typename
            nextToken
          }
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          supportingDocuement
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteHtCampaignSubscription>;

  OnCreateWorkflowStepListener: Observable<
    OnCreateWorkflowStepSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateWorkflowStep {
        onCreateWorkflowStep {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateWorkflowStepSubscription>;

  OnUpdateWorkflowStepListener: Observable<
    OnUpdateWorkflowStepSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateWorkflowStep {
        onUpdateWorkflowStep {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateWorkflowStepSubscription>;

  OnDeleteWorkflowStepListener: Observable<
    OnDeleteWorkflowStepSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteWorkflowStep {
        onDeleteWorkflowStep {
          __typename
          id
          sequence
          stepName
          role
          campaignId
          campaignName
          tags
          theme
          department
          startDate
          endDate
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          visibility
          autoApproval
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteWorkflowStepSubscription>;

  OnCreateUserListener: Observable<OnCreateUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateUser {
        onCreateUser {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateUserSubscription>;

  OnUpdateUserListener: Observable<OnUpdateUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUser {
        onUpdateUser {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateUserSubscription>;

  OnDeleteUserListener: Observable<OnDeleteUserSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUser {
        onDeleteUser {
          __typename
          udType
          id
          firstName
          lastName
          userType
          required
          emailId
          favIdea
          score
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteUserSubscription>;

  OnCreateHtIdeaListener: Observable<OnCreateHtIdeaSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnCreateHtIdea {
        onCreateHTIdea {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateHtIdeaSubscription>;

  OnUpdateHtIdeaListener: Observable<OnUpdateHtIdeaSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnUpdateHtIdea {
        onUpdateHTIdea {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateHtIdeaSubscription>;

  OnDeleteHtIdeaListener: Observable<OnDeleteHtIdeaSubscription> = API.graphql(
    graphqlOperation(
      `subscription OnDeleteHtIdea {
        onDeleteHTIdea {
          __typename
          id
          title
          campaignId
          shortDescription
          longDescription
          like
          comments {
            __typename
            commnetText
            emailId
          }
          tags
          contributor
          author
          postedDate
          ideaStatus
          lastUpdatedBy
          ragStatus
          questionnaire {
            __typename
            id
            campaignId
            stepId
            ideaId
          }
          modelValue {
            __typename
            key
            value
            multiValue
          }
          theme
          department
          fav
          campaignName
          visibility
          promotionImageURL
          supportingDocName
          likesCount
          commentCount
          favCount
          type
          ideaTheme
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteHtIdeaSubscription>;

  OnCreateTrafficLogListener: Observable<
    OnCreateTrafficLogSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTrafficLog {
        onCreateTrafficLog {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnCreateTrafficLogSubscription>;

  OnUpdateTrafficLogListener: Observable<
    OnUpdateTrafficLogSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTrafficLog {
        onUpdateTrafficLog {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnUpdateTrafficLogSubscription>;

  OnDeleteTrafficLogListener: Observable<
    OnDeleteTrafficLogSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTrafficLog {
        onDeleteTrafficLog {
          __typename
          id
          userEmail
          action
          date
          context
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<OnDeleteTrafficLogSubscription>;
}
