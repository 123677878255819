import { APIService } from './API.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  form = new FormGroup({});
  model = { email: 'email@gmail.com' };
  fields: FormlyFieldConfig[] = [
    {
      key: 'email',
      type: 'input',
      templateOptions: {
        label: 'Email address',
        placeholder: 'Enter email',
        required: true,
      }
    }
  ];

  campaign: Array<any>;
  constructor(private apiService: APIService) {}

  createCampaign() {
    this.apiService.CreateHtCampaign({
      userId: '1',
      longDescription: '',
    });
  }

  async ngOnInit() {
    // this.apiService.ListHtCampaigns().then((evt) => {
    //   this.campaign = evt.items;
    // });
  }
}
