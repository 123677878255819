import { Pipe, PipeTransform } from '@angular/core';
import { strip } from '../utils/common.utils';

@Pipe({
  name: 'userName'
})
export class UserNamePipe implements PipeTransform {

  transform(value: any | any[], ...args: unknown[]): unknown {

    if (value) {
      if (Array.isArray(value)) {
        return value.map(val => {
          return strip(val);
        });
      }
      return strip(value);
    }
    return value;
  }

}
