import { NgModule } from '@angular/core';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserNamePipe } from './shared/pipe/user-name.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  swipeEasing: false,
  scrollYMarginOffset: 1,
};

const dependencies = [
  PerfectScrollbarModule,
  ChartsModule,
  FormsModule,
  ReactiveFormsModule,
  NgSelectModule
];
@NgModule({
  declarations: [
    UserNamePipe
  ],
  imports: [
    ModalModule.forChild(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    ...dependencies
  ],
  exports: [
    BsDatepickerModule,
    BsDropdownModule,
    AccordionModule,
    TooltipModule,
    UserNamePipe,
    ...dependencies],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
})
export class AppDependenciesModule {
}
