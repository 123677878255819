/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'eu-west-1',
    aws_appsync_graphqlEndpoint:'https://azxqsspftvamhk4sjq7xh7s2la.appsync-api.eu-west-1.amazonaws.com/graphql',
    aws_appsync_region: 'eu-west-1',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-d2wcuzevprf5tp3hkbdrmhtl7i',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_M1Lo3CiEa',
    aws_user_pools_web_client_id: '48oaov9tpce9mtpup4c95d506g',
    aws_cognito_identity_pool_id:
        'eu-west-1:5dcabb3c-b7a2-4419-813c-2359b91d219d',
    oauth: {
        domain: 'innovation-hub-dev.auth.eu-west-1.amazoncognito.com',
        scope: [
            'phone',
            'email',
            'openid',
            'profile',
            'aws.cognito.signin.user.admin',
        ],
        redirectSignIn: 'https://ideate.ihsmarkit.com/',
        redirectSignOut: 'https://ideate.ihsmarkit.com/',
        responseType: 'code',
    },
    Storage: {
        AWSS3: {
            bucket: 'markitconnect-dev-storage', // REQUIRED -  Amazon S3 bucket
            region: 'eu-west-1', // OPTIONAL -  Amazon service region
        },
    },
};

export default awsmobile;
