/**
 * strip email domain from full address.
 *
 * @param input email string
 * @returns string
 */
export function strip(input: string): string {
    if (input) {
        const pattern = /(.*?)@.*?/;
        const output = input.match(pattern);
        return output && output[1] ? output[1] : '';
    }
    return input;
}
